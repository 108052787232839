.tile-background {
    background-image: url("../../assets/heroBg.jpeg");
    background-size: cover;    
    background-position: center;
}
.tile-background-two {
    background-image: url("../../assets/heroBg.jpeg");
    background-size: cover;    
    background-position: center;
}
.tile-background-three {
    background-image: url("../../assets/heroBg.jpeg");
    background-size: cover;
    background-position: center;
}
.tile-background-four {
    background-image: url("../../assets/heroBg.jpeg");
    background-size: cover;    
    background-position: center;
}
.tile-background-five {
    background-image: url("../../assets/heroBg.jpeg");
    background-size: cover;    
    background-position: center;
}
.tile-background-six {
    background-image: url("../../assets/heroBg.jpeg");
    background-size: cover;
    background-position: center;
}