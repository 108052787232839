.map-responsive{

    overflow:hidden;

    padding-bottom:50vh;

    position:relative;

    height:0;

}

.map-responsive iframe{

    left:0;

    top:0;

    height:50vh;

    width:100vw;

    position:absolute;

}