.advantages-bg{
    overflow: auto;
    position: relative;

    background-position: top;
    background-repeat: no-repeat;
    background-image: url('../../assets/heroBg.jpeg');
    background-size: cover;
    background-attachment: fixed;
}
.advantages-bg::before{
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;
    background-color: rgba(75, 137, 202, 0.8); 
  
    /* background-image: url('../../assets/heroBg.jpeg');*/
    background-size: cover;
    background-attachment: fixed;
    width: 100%;
    height: 100%;
  
    background-position: top;
    background-repeat: no-repeat;
    /* filter: brightness(20%); */
}

.prio {
    z-index:10;
}