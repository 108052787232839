    input[type="text"]:focus {
    outline: none;
    border-bottom: 3px solid #4B89CA;
    border-right: 3px solid #4B89CA;
  }
  input {
    padding: 1.25rem;
    margin: 0.5rem 0;
    border-bottom: 3px solid white;
    border-right: 3px solid white;
    font-family: "Montserrat", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: 0.1rem;
    transition: all 0.3s;
  }
  input::-webkit-input-placeholder {
    color: #19130B;
    font-family: "Montserrat", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: 0.1rem;
  }
  input:-moz-placeholder {
    color: #19130B;
    font-family: "Montserrat", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: 0.1rem;
  }
  